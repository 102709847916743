import React from "react"
import Script from "next/script"
import logger from "pitch45-common/logging/logger"

const GoogleAnalytics = () => {
  const GOOGLE_ANALYTICS_TAG_ID = process.env.NEXT_PUBLIC_DASHBOARD_ANALYTICS_TAG

  if (!GOOGLE_ANALYTICS_TAG_ID) {
    logger.logWarning("Google Analytics tag not found")
    return null
  }

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_TAG_ID}`}
      />

      <Script id="dashboard-analytics-script" strategy="lazyOnload">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GOOGLE_ANALYTICS_TAG_ID}', {
              page_path: window.location.pathname,
              });
          `}
      </Script>
    </>
  )
}

export default GoogleAnalytics
