import React from "react"
import { CSSObject } from "@emotion/react"
import { IconButton } from "@mui/material"
import Icon, { Icons } from "../icon/icon"
import { StyledMenu, useMenu } from "../menu/menu"
import { iconSizing } from "../../theme"
import { AppTheme, color } from "pitch45-common/theme"
import { theme } from "pitch45-common/theme/theme"
export interface MoreMenuItem {
  label: string
  onClick?: () => void
  labelStyle?: CSSObject
  conditional?: boolean
}

function getStyles(theme: AppTheme) {
  const styles = {
    ICON: {
      color: theme.colors.text,
      "&:hover": {
        backgroundColor: theme.dark ? theme.colors.halfTransparentBackground : color.menuItemHover,
      },
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export interface MoreMenuButtonProps {
  fontSize?: number
  style?: CSSObject
  buttonStyle?: CSSObject
  menuItems: MoreMenuItem[]
  pageTheme?: AppTheme
  iconOverride?: Icons
}

export function MoreMenuButton({
  style,
  menuItems,
  fontSize,
  buttonStyle,
  iconOverride,
  pageTheme = theme.pitch45Light,
}: MoreMenuButtonProps) {
  const { handleClick, handleClose, anchorEl, open, renderMenuItems } = useMenu()
  const styles = getStyles(pageTheme)

  return (
    <div css={style}>
      <IconButton
        onClick={(e) => {
          handleClick(e)
        }}
        css={buttonStyle}
      >
        <Icon
          icon={iconOverride || Icons.MoreVert}
          css={[
            styles.ICON,
            {
              fontSize: fontSize || iconSizing.small,
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          ]}
        />
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} theme={pageTheme}>
        {renderMenuItems(
          menuItems.filter((item) => typeof item.conditional === "undefined" || item.conditional),
        )}
      </StyledMenu>
    </div>
  )
}

export default MoreMenuButton
