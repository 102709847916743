import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { Entity, EntityType } from "./entity"
import { ExternalLinkModel } from "./external-link"
import { DiscoverabilityStatus } from "./group"

export enum RegistrationStatus {
  UserCreated = "UserCreated",
  /** @deprecated see user.emailConfirmed instead */
  EmailVerified = "EmailVerified",
  RegistrationComplete = "RegistrationComplete",
}

export const PersonalUserModel = types
  .model("PersonalUserModel")
  .props({
    id: types.string,
    firstName: types.maybe(types.string),
    lastName: types.maybe(types.string),
    email: types.string,
    phone: types.maybe(types.string),
    locationCity: types.maybe(types.string),
    locationState: types.maybe(types.string),
    locationZip: types.maybe(types.string),
    title: types.maybe(types.string),
    affiliation: types.maybe(types.string),
    tagline: types.maybe(types.string),
    bio: types.maybe(types.string),
    website: types.maybe(types.string),
    registrationStatus: types.maybe(StringEnum(RegistrationStatus)),
    avatarAssetId: types.maybe(types.string),
    profilePitchId: types.maybe(types.string),
    emailConfirmed: types.maybe(types.boolean),
    profileLink: types.maybe(ExternalLinkModel),
    mfaEnabled: types.maybe(types.boolean),
    discoverability: types.enumeration([DiscoverabilityStatus.None, DiscoverabilityStatus.Public]),
  })
  .views((self) => ({
    get name(): string {
      if (self.lastName) {
        return `${self.firstName?.trim()} ${self.lastName?.trim()}`
      } else {
        return self.firstName || ""
      }
    },
  }))
  .views((self) => ({
    toEntity(): Entity {
      return {
        id: self.id,
        name: self.name || "",
        assetId: self.avatarAssetId,
        type: EntityType.User,
        parentId: null,
      }
    },
    get location() {
      const parts: string[] = []
      if (self.locationCity) {
        parts.push(self.locationCity.trim())
      }
      if (self.locationState) {
        parts.push(self.locationState)
      }
      return parts.join(", ")
    },
  }))

export type PersonalUser = Instance<typeof PersonalUserModel>
