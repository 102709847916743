import { ExternalLinkType } from "../models/external-link"

export const getResourcePath = (linkType: ExternalLinkType, resourceId: string) => {
  switch (linkType) {
    case ExternalLinkType.PitchViewer:
      return `/watch/${resourceId}`
    case ExternalLinkType.PlaylistViewer:
      return `/watch/playlist/${resourceId}`
    case ExternalLinkType.PitchEditor:
      return `/pitchEditor/${resourceId}/edit`
    case ExternalLinkType.Playlist:
      return `/playlist/${resourceId}`
    case ExternalLinkType.UserProfile:
      return `/profile/user/${resourceId}`
    case ExternalLinkType.GroupMemberProfileVideos:
      return `/watch/group/${resourceId}/members`
    default:
      throw new Error("Unsupported resource link type")
  }
}
