import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Playlist, PlaylistParams } from "../../models/playlist"
import { Pitch } from "../../models/pitch"

export type PitchWithPlaylistMetadata = { pitch: Pitch; tags: string[]; ordinal: number }

export type PlaylistPitchesResult = { pitches: PitchWithPlaylistMetadata[] }

export type PlaylistPitchesPlaylistResult = {
  pitches: { pitch: Pitch; tags: string[]; orginal: number }[]
  playlistId
}

export type SamplePlaylistsResult = {
  samplePlaylists: { [samplePlaylistType: string]: Playlist }
}

export type PlaylistResult = { playlist: Playlist }

export type PlaylistsResult = { playlists: Playlist[] }

export type PlaylistIdsResult = { playlistIds: string[] }

export type CreatedItemResult = { itemId: string }

export type ExistsInPlaylistResult = { existingPitchIds: string[] }

export type ScheduledPitchesResult = { scheduledPitches: { pitch: Pitch; scheduledUtc: Date }[] }

const API_PATH = "api/Playlist/"

/**
 * This is for mapping the new flattened visibility prop `contentVisibility` to the old `visibility` prop on playlist objects.
 * We used to be have two visibility props on the playlist: `visibility` and `playlistPitchVisibility`.
 * This can be cleaned up after one more round of backwards compatibility changes in the API.
 * @param playlist
 * @returns
 */
const mapVisibility = (playlist: Playlist) => {
  if ((playlist as any).contentVisibility) {
    playlist.visibility = (playlist as any).contentVisibility
  }
  return playlist
}

export class PlaylistApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async createPlaylist(playlistParams: PlaylistParams): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(API_PATH, playlistParams)
    if (!response.ok) {
      throw new ApiError("PlaylistApi.createPlaylist", response)
    }
  }

  async updatePlaylist(playlistParams: PlaylistParams): Promise<void> {
    if (!playlistParams.id) {
      throw new Error("Playlist ID is required for update")
    }
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      API_PATH + playlistParams.id,
      playlistParams,
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.updatePlaylist", response)
    }
  }

  async deletePlaylist({ playlistId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(API_PATH + playlistId)
    if (!response.ok) {
      throw new ApiError("PlaylistApi.deletePlaylist", response)
    }
  }

  async movePitch({ playlistId, pitchId, nextPitchId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${playlistId}/pitch/move/${pitchId}`,
      {
        nextPitchId,
      },
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.movePitch", response)
    }
  }

  async addPitches({
    playlistId,
    pitchIds,
  }: {
    playlistId: string
    pitchIds: string[]
  }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${playlistId}/pitch/add`,
      pitchIds,
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.addPitches", response)
    }
  }

  async removePitches({
    playlistId,
    pitchIds,
  }: {
    playlistId: string
    pitchIds: string[]
  }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${playlistId}/pitch/remove`,
      pitchIds,
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.removePitches", response)
    }
  }

  async addProfilePitch(pitchId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `profile/pitch/${pitchId}`,
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.addProfilePitch", response)
    }
  }

  async removeProfilePitch(pitchId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      API_PATH + `profile/pitch/${pitchId}`,
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.removeProfilePitch", response)
    }
  }

  async bookmarkPitches({ pitchIds }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `bookmarks/pitch/add`,
      pitchIds,
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.bookmarkPitches", response)
    }
  }

  async removeBookmarkedPitches({ pitchIds }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `bookmarks/pitch/remove`,
      pitchIds,
    )
    if (!response.ok) {
      throw new ApiError("PlaylistApi.removeBookmarkedPitches", response)
    }
  }

  async getPlaylist(playlistId): Promise<PlaylistResult> {
    const response: ApiResponse<Playlist> = await this.api.apisauce.get(API_PATH + `${playlistId}`)
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getPlaylist", response)
    }
    return {
      playlist: mapVisibility(response.data),
    }
  }

  async getPendingPitchActionPlaylists(): Promise<PlaylistsResult> {
    const response: ApiResponse<PlaylistsResult> = await this.api.apisauce.get(
      API_PATH + `pending/pitch-action`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getPendingPitchActionPlaylists", response)
    }
    return {
      playlists: response.data.playlists.map(mapVisibility),
    }
  }

  async getScheduledPitches(playlistId: string): Promise<ScheduledPitchesResult> {
    const response: ApiResponse<ScheduledPitchesResult> = await this.api.apisauce.get(
      API_PATH + `${playlistId}/scheduled`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getScheduledPitches", response)
    }
    return response.data
  }

  async getAllPitches(playlistId): Promise<PlaylistPitchesResult> {
    const response: ApiResponse<PlaylistPitchesResult> = await this.api.apisauce.get(
      API_PATH + `${playlistId}/pitch`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getAllPitches", response)
    }
    return response.data
  }

  async getGroupMemberProfilePitches(groupId: string): Promise<PlaylistPitchesResult> {
    const response: ApiResponse<PlaylistPitchesResult> = await this.api.apisauce.get(
      API_PATH + `${groupId}/profile-pitch`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getGroupMemberProfilePitches", response)
    }
    return response.data
  }

  async getGroupMemberProfilePitchesExists(groupId: string): Promise<boolean> {
    const response: ApiResponse<{ exists: boolean }> = await this.api.apisauce.get(
      API_PATH + `${groupId}/profile-pitch/exists`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.groupMemberProfilePitchesExists", response)
    }
    return response.data.exists
  }

  async getAllBookmarkedPitches(entityIds?: string[]): Promise<PlaylistPitchesResult> {
    const response: ApiResponse<PlaylistPitchesResult> = await this.api.apisauce.get(
      API_PATH + `bookmarks/pitch`,
      {
        entityIds,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getAllBookmarkedPitches", response)
    }
    return response.data
  }

  async getAllBookmarkedPlaylists(entityIds?: string[]): Promise<PlaylistsResult> {
    const response: ApiResponse<PlaylistsResult> = await this.api.apisauce.get(
      API_PATH + `bookmarks/playlist`,
      {
        entityIds,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getAllBookmarkedPlaylists", response)
    }
    return {
      playlists: response.data.playlists.map(mapVisibility),
    }
  }

  async getSamplePlaylists(samplePlaylistTypes: string[]): Promise<SamplePlaylistsResult> {
    const response: ApiResponse<SamplePlaylistsResult> = await this.api.apisauce.get(
      API_PATH + "samples",
      {
        samplePlaylistTypes,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.getSamplePlaylists", response)
    }
    return response.data
  }

  async existsInPlaylist(playlistId: string, pitchIds: string[]): Promise<ExistsInPlaylistResult> {
    const response: ApiResponse<ExistsInPlaylistResult> = await this.api.apisauce.post(
      API_PATH + `${playlistId}/pitch/exists`,
      {
        pitchIds,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PlaylistApi.existsInPlaylist", response)
    }
    return response.data
  }
}
