export const fetchProfilePitchQueryKey = (userId) => ["fetchProfilePitch", userId]
export const fetchGroupQueryKey = (groupId) => ["fetchGroup", groupId]
export const fetchGroupsQueryKey = (organizationId) => ["fetchGroups", organizationId]
export const fetchGroupsForAdminQueryKey = (organizationId) => [
  "fetchGroupsForAdmin",
  organizationId,
]
export const fetchPendingOrganizationInvitationsQueryKey = (organizationId) => [
  "getPendingOrganizationInvitations",
  organizationId,
]
export const fetchPendingGroupInvitationsQueryKey = (groupId) => [
  "getPendingGroupInvitations",
  groupId,
]
export const fetchUserAffiliationsQueryKey = (userId) => ["fetchUserAffiliations", userId]
export const homeSearchQueryKey = (searchString) => ["search", searchString]
export const homeUserSearchQueryKey = (searchString) => ["searchUsers", searchString]
export const fetchEditorAttachmentsQueryKey = (pitchId) => ["fetchEditorAttachments", pitchId]
export const fetchAttachmentsQueryKey = (pitchId) => ["fetchAttachments", pitchId]
export const fetchPublishedPitchCountQueryKey = (userId) => ["fetchPublishedPitchCount", userId]
export const fetchPitchReactionDataQueryKey = (pitchId) => ["fetchPitchReactionData", pitchId]
export const fetchPitchStatsQueryKey = (pitchId) => ["fetchPitchStats", pitchId]
