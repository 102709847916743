import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError, getGeneralApiProblem } from "./api-problem"

const API_PATH = "api/EmailConfirmation/"

export class EmailConfirmationApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async requestEmailConfirmation(): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}request`, {})

    if (!response.ok) {
      throw new ApiError("EmailConfirmationApi.requestEmailConfirmation", response)
    }
  }

  async confirmEmail(token): Promise<boolean> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}confirm`, {
      token: encodeURIComponent(token),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        // returns a 400 if the token is invalid (expired or already used)
        if (problem.kind === "bad-request") {
          return false
        }
      }

      throw new ApiError("EmailConfirmationApi.confirmEmail", response)
    }

    return true
  }
}
