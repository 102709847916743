import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils"
import { ResourceModel } from "./resource"

export enum AssignmentUserStatus {
  Due = "Due",
  Done = "Done",
  Overdue = "Overdue",
  NotCompleted = "NotCompleted",
  Hidden = "Hidden",
  Scheduled = "Scheduled",
}

export const AssignmentUserModel = types.model("AssignmentUser").props({
  id: types.identifier,
  userId: types.string,
  assignmentId: types.string,
  dueUtc: DateTime,
  completedUtc: types.maybe(DateTime),
  hiddenUtc: types.maybe(DateTime),
  scheduledUtc: types.maybe(DateTime),
  resource: ResourceModel,
  publishOnDeadline: types.boolean,
})

export type AssignmentUser = Instance<typeof AssignmentUserModel>
