// types
import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { PublicUser } from "../../models/public-user"
import { ApiError } from "./api-problem"
import { PersonalUser } from "../../models/personal-user"
import { DiscoverabilityStatus } from "../../models/group"

const API_PATH = "api/User/"

export type PublicUsersResult = { users: PublicUser[] }
export type PublicUserResult = { user: PublicUser }
export type PersonalUserResult = { user: PersonalUser }
export type MfaAuthResult = { authQrCodeDataUrl: string }
export type MfaEnabledResult = { isEnabled: boolean }

export class UserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getUser(userId): Promise<PublicUserResult> {
    const response: ApiResponse<PublicUser> = await this.api.apisauce.get(`${API_PATH}${userId}`)
    if (!response.ok || !response.data) {
      throw new ApiError("UserApi.getUser", response)
    }
    return { user: response.data }
  }

  async getCurrentUser(): Promise<PersonalUserResult> {
    const response: ApiResponse<PersonalUser> = await this.api.apisauce.get(`${API_PATH}current`)
    if (!response.ok || !response.data) {
      throw new ApiError("UserApi.getCurrentUser", response)
    }
    return { user: response.data }
  }

  async updateCurrentUser(user): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(`${API_PATH}`, user)
    if (!response.ok) {
      throw new ApiError("UserApi.updateCurrentUser", response)
    }
  }

  async deleteCurrentAccount(): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(`${API_PATH}self-delete`)
    if (!response.ok) {
      throw new ApiError("UserApi.deleteCurrentAccount", response)
    }
  }

  async updateDiscoverability(userId, discoverability: DiscoverabilityStatus): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}user/${userId}/discoverability`,
      { discoverability },
    )
    if (!response.ok) {
      throw new ApiError("UserApi.updateDiscoverability", response)
    }
  }

  async enableMfa(): Promise<MfaAuthResult> {
    const response: ApiResponse<MfaAuthResult> = await this.api.apisauce.post(
      `${API_PATH}mfa/enable`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("UserApi.enableMfa", response)
    }
    return response.data
  }

  async disableMfa(): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}mfa/disable`)
    if (!response.ok) {
      throw new ApiError("UserApi.disableMfa", response)
    }
  }
}
