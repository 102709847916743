import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ApiResponse } from "apisauce"
import { PublicUser } from "../../models/public-user"
import { Group } from "../../models/group"
import { Organization } from "../../models/organization"

export type EntityResult = { organizations: Organization[]; groups: Group[]; users: PublicUser[] }

const API_PATH = "api/Entity/"

export class EntityApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getEntities(entityIds: string[]): Promise<EntityResult> {
    const response: ApiResponse<EntityResult> = await this.api.apisauce.post(
      `${this.api.url}/${API_PATH}`,
      { entityIds },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("EntityApi.getEntities", response)
    }

    return response.data
  }
}
