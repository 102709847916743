import { ManagedRole, RoleLabel } from "../models/organization-role"

export const getRoleLabel = (managedRole?: string) => {
  switch (managedRole) {
    case ManagedRole.OrganizationOwner:
      return RoleLabel.OrganizationOwner
    case ManagedRole.OrganizationAdmin:
      return RoleLabel.OrganizationAdmin
    case ManagedRole.OrganizationMember:
      return RoleLabel.OrganizationMember
    case ManagedRole.GroupAdmin:
      return RoleLabel.GroupAdmin
    case ManagedRole.GroupCreator:
      return RoleLabel.GroupCreator
    case ManagedRole.GroupMember:
      return RoleLabel.GroupMember
    default:
      // TODO I don't think it's safe to assume that Guest is the default role
      return RoleLabel.OrganizationGuest
  }
}
