import { flow, Instance, types, toGenerator, IStateTreeNode, getParent } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { OrganizationUserApi } from "../services/api/organization-user-api"
import { withOrganizationStore } from "./organization-store"
import { withGroupStore } from "./group-store"
import { withUserStore } from "./user-store"
import { MembershipRequestApi } from "../services/api/membership-request-api"
import { RootStoreModel } from "./root-store"
import { flatten, uniq } from "lodash-es"
import { withSessionStore } from "./session-store"
import { UserMembershipModel } from "../models/organization-user"
import { OrganizationModel, Organization } from "../models/organization"
import { Permission } from "../models/session"
import { Entity } from "../models/entity"
import { Group } from "../models/group"

export const SessionMembershipStoreModel = types
  .model("SessionMembershipStore")
  .props({
    sessionMemberships: types.array(UserMembershipModel),
    sessionOrganizations: types.array(types.safeReference(OrganizationModel)),
  })
  .extend(withEnvironment)
  .extend(withOrganizationStore)
  .extend(withUserStore)
  .extend(withGroupStore)
  .extend(withSessionStore)
  .actions((self) => ({
    fetchSessionOrganizations: flow(function* () {
      const organizationUserApi = new OrganizationUserApi(self.environment.api)
      const result = yield* toGenerator(organizationUserApi.getForUser())
      self.sessionMemberships.replace(result.memberships)
      const orgs = self.organizationStore.putOrganizations(result.organizations)
      self.groupStore.putGroups(result.groups)
      for (const group of result.groups) {
        self.groupStore.putGroupSummaries(group, result.groupMemberCount?.[group.id] || 0)
      }
      self.sessionOrganizations.replace(orgs)
      return orgs
    }),
  }))
  .actions((self) => ({
    leaveGroup: flow(function* (groupId: string) {
      const membershipApi = new MembershipRequestApi(self.environment.api)
      yield membershipApi.leaveGroup(groupId)
      self.sessionMemberships.replace(
        self.sessionMemberships.filter((um) => um?.entityId !== groupId),
      )
    }),
    leaveOrganization: flow(function* (organizationId: string) {
      const membershipApi = new MembershipRequestApi(self.environment.api)
      yield membershipApi.leaveOrganization(organizationId)
      self.sessionMemberships.replace(
        self.sessionMemberships.filter(
          (um) => um?.parentId !== organizationId && um?.entityId !== organizationId,
        ),
      )
    }),
  }))
  .views((self) => ({
    // used on claim invite screen
    membershipsByOrganizationId: (orgId) => {
      return self.sessionMemberships.filter((um) => um?.entityId === orgId)
    },

    get sessionGroupUsers() {
      // used in reg code screen
      return self.sessionMemberships.filter(
        (mem) => mem?.userId === self.sessionStore.currentUser?.id,
      )
    },
  }))
  .views((self) => ({
    get sessionEntityIds() {
      return uniq(
        flatten(
          self.sessionMemberships
            .filter((um) => um?.userId === self.sessionStore.currentUser?.id)
            .map((um) => um?.entityId),
        ),
      )
    },
    get sessionGroups() {
      const result = self.sessionGroupUsers
        ?.map((mem) => self.groupStore.groups.get(mem?.entityId))
        .filter((g): g is Group => Boolean(g))

      return result
    },
  }))
  .views((self) => ({
    sessionGroupsByOrganizationId(orgId: string) {
      return self.sessionGroups.filter((g) => g.organizationId === orgId)
    },
    get sessionGuestGroups() {
      return self.sessionGroups.filter((g) => g && !g.enforceOrganizationMembership)
    },
  }))
  .views((self) => ({
    sessionGroupsWithPermission(permission: Permission) {
      return self.sessionOrganizations
        .map((o) => [
          o?.toEntity(),
          ...self.groupStore.groupsByOrganizationId(o?.id).map((g) => g.toEntity()),
        ])
        .flat()
        .filter((e: Entity | undefined): e is Entity =>
          Boolean(e && self.sessionStore.hasPermission(e.id, permission)),
        )
    },
  }))
  .views((self) => ({
    get sessionAdminOrganizations(): Organization[] {
      return self.sessionOrganizations
        .filter((o): o is Organization => Boolean(o))
        .filter((o) => {
          const canManageOrg = self.sessionStore.hasPermission(o.id, Permission.ManageOrganization)
          const canManageAnyGroup = self
            .sessionGroupsByOrganizationId(o.id)
            .some((g) => self.sessionStore.hasPermission(g.id, Permission.ManageGroup))
          return canManageOrg || canManageAnyGroup
        })
    },
  }))

export type SessionMembershipStore = Instance<typeof SessionMembershipStoreModel>
export const withSessionMembershipStore = (self: IStateTreeNode) => ({
  views: {
    get sessionMembershipStore(): SessionMembershipStore {
      return getParent<Instance<typeof RootStoreModel>>(self).sessionMembershipStore
    },
  },
})
