import { Instance, cast, types } from "mobx-state-tree"
import { DateTime, StringEnum } from "../utils"
import { EntityModel } from "./entity"

export enum ResourceActionType {
  AddToPlaylist = "AddToPlaylist",
}

export enum RecurrencePreset {
  Once = "Once",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly",
  Weekdays = "Weekdays",
}

export const AssignmentUserDetailsModel = types.model("AssignmentUserDetails").props({
  entity: EntityModel,
  lastVideoAddedUtc: types.maybe(DateTime),
})
export type AssignmentUserDetails = Instance<typeof AssignmentUserDetailsModel>

export const AssignmentParamsModel = types.model("AssignmentParams").props({
  id: types.string,
  initialDueDate: DateTime,
  timezoneId: types.string,
  users: types.array(EntityModel),
  assignToCurrentAndFutureMembers: types.boolean,
  assignToCurrentMembers: types.boolean,
  recurrencePreset: StringEnum(RecurrencePreset),
  publishOnDeadline: types.boolean,
})
export type AssignmentParams = Instance<typeof AssignmentParamsModel>

export const AssignmentModel = types
  .model("Assignment")
  .props({
    id: types.identifier,
    resourceId: types.string,
    resourceActionType: StringEnum(ResourceActionType),
    description: types.string,
    createdUtc: DateTime,
    updatedUtc: types.maybe(DateTime),
    deletedUtc: types.maybe(DateTime),
    initialDueDate: types.maybe(DateTime),
    timezoneId: types.string,
    recurrencePreset: StringEnum(RecurrencePreset),
    ical: types.maybe(types.string),
    userDetails: types.array(AssignmentUserDetailsModel),
    assignToCurrentAndFutureMembers: types.boolean,
    publishOnDeadline: types.boolean,
  })
  .views((self) => ({
    toAssignmentParams(): AssignmentParams {
      return {
        id: self.id,
        initialDueDate: self.initialDueDate,
        timezoneId: self.timezoneId,
        users: cast(self.userDetails.map((userDetail) => userDetail.entity)),
        assignToCurrentAndFutureMembers: self.assignToCurrentAndFutureMembers,
        // Just current members isn't persisted on the model, since it's a temporary way
        // to select all members and gets replaced by the users. But if future members
        // is selected, we should show the current members pill as well.
        assignToCurrentMembers: self.assignToCurrentAndFutureMembers,
        recurrencePreset: self.recurrencePreset,
        publishOnDeadline: self.publishOnDeadline,
      }
    },
  }))

export type Assignment = Instance<typeof AssignmentModel>
