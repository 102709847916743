import { types } from "mobx-state-tree"
import { DateTime, StringEnum } from "../utils"
import { EntityModel } from "./entity"

export enum ResourceType {
  Pitch = "Pitch",
  Playlist = "Playlist",
  User = "User",
  Group = "Group",
  Saved = "Saved",
}

export const ResourceModel = types.model("Resource").props({
  resourceId: types.string,
  title: types.string,
  resourceType: StringEnum(ResourceType),
  entity: EntityModel,
  thumbnailAssetIds: types.maybe(types.array(types.string)),
  createdUtc: DateTime,
  pitchCount: types.maybe(types.number),
})
