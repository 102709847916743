import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import { color } from "../../theme/color"
import { typography } from "pitch45-common/theme/typography"
import { unitSpacing } from "../../theme/spacing"
import { fontSizing } from "../../theme"

interface StyledTabProps {
  value: string
  label: string
  size?: number
  iconPosition?: "start" | "end"
  icon?: React.ReactElement
}

export const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  () => ({
    ...typography.semibold,
    fontSize: fontSizing.medium,
    textTransform: "none",
    minHeight: "48px",
    minWidth: 0,
    marginRight: unitSpacing.quad,
    color: color.palette.slateGrey,
    flexDirection: "row",
    padding: 0,
    "&:hover": {
      color: color.palette.black,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: color.primary,
    },
    "&.Mui-focusVisible": {
      backgroundColor: color.primary,
    },
  }),
)

interface StyledTabsProps {
  children?: React.ReactNode
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: color.primary,
    height: "3px",
  },
})

interface TabPanelProps {
  children?: React.ReactNode
  activeTab: string
  value: string
}

export function TabPanel(props: TabPanelProps) {
  const { children, activeTab, value, ...rest } = props

  return (
    <div role="tabpanel" hidden={value !== activeTab} {...rest}>
      {value === activeTab && (
        <Box
          css={{
            width: "100%",
            height: "100%",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}
