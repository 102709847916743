import React, { ReactNode } from "react"
import { observer } from "mobx-react-lite"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

interface LoadableProps {
  /**
   * show the loading spinner instead of the children content
   */
  loading?: boolean

  /**
   * the content of the div if it loaded successfully
   */
  children: ReactNode
}

/**
 * View that displays either its children or a loading state
 */
export const Loadable = observer(function Loadable(props: LoadableProps) {
  const { loading, children, ...rest } = props

  if (loading) {
    return (
      <div {...rest}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  }

  return <div {...rest}>{children}</div>
})
