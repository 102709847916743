import { flow, getParent, Instance, IStateTreeNode, types, toGenerator } from "mobx-state-tree"

import { withEnvironment } from "./extensions/with-environment"
import { MembershipRequest, MembershipRequestModel } from "../models/membership-request"
import { withSessionStore } from "./session-store"
import { withUserStore } from "./user-store"
import {
  MembershipRequestApi,
  MembershipRequestQuery,
} from "../services/api/membership-request-api"
import { RootStoreModel } from "./root-store"

export const MembershipRequestStoreModel = types
  .model("MembershipRequestStore")
  .props({
    membershipRequests: types.map(MembershipRequestModel),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .extend(withUserStore)
  .actions((self) => ({
    putMembershipRequests: (membershipRequests: MembershipRequest[]) => {
      return membershipRequests.map((m) => self.membershipRequests.put(m))
    },
  }))
  .actions((self) => ({
    fetchCurrentUserMembershipRequestForEntityId: flow(function* (entityId: string) {
      const membershipRequestApi = new MembershipRequestApi(self.environment.api)
      const result = yield* toGenerator(membershipRequestApi.getMembershipRequest(entityId))
      if (result.membershipRequest) {
        return self.putMembershipRequests([result.membershipRequest])[0]
      }
      return null
    }),
    fetchPendingMembershipRequests: flow(function* (entityId: string) {
      const membershipRequestApi = new MembershipRequestApi(self.environment.api)
      const result = yield* toGenerator(membershipRequestApi.getPendingRequestsByEntity(entityId))

      // delete existing records for entity
      for (const membershipRequest of self.membershipRequests.values()) {
        if (!membershipRequest) {
          continue
        }
        if (
          membershipRequest.groupId === entityId ||
          membershipRequest.organizationId === entityId
        ) {
          self.membershipRequests.delete(membershipRequest.id)
        }
      }
      return self.putMembershipRequests(result.membershipRequests)
    }),
  }))
  .actions((self) => ({
    requestGroupMembership: flow(function* (entityId: string) {
      const membershipRequestApi = new MembershipRequestApi(self.environment.api)
      yield membershipRequestApi.requestGroupMembership(entityId)
      yield self.fetchCurrentUserMembershipRequestForEntityId(entityId)
      return true
    }),
    acceptMembershipRequest: flow(function* (membershipRequestId: string) {
      const membershipRequest = self.membershipRequests.get(membershipRequestId)
      const membershipRequestApi = new MembershipRequestApi(self.environment.api)

      yield membershipRequestApi.acceptMembershipRequest(membershipRequestId)

      // reload all entity membership requests // TODO inefficient
      if (membershipRequest) {
        yield self.fetchPendingMembershipRequests(membershipRequest.entityId)
      }

      return true
    }),
    rejectMembershipRequest: flow(function* (membershipRequestId: string) {
      const membershipRequest = self.membershipRequests.get(membershipRequestId)
      const membershipRequestApi = new MembershipRequestApi(self.environment.api)

      yield membershipRequestApi.rejectMembershipRequest(membershipRequestId)

      // reload all entity membership requests // TODO inefficient
      if (membershipRequest) {
        yield self.fetchPendingMembershipRequests(membershipRequest.entityId)
      }

      return true
    }),
    searchOrganizationMembers: flow(function* (
      organizationId: string,
      query: MembershipRequestQuery,
    ) {
      const membershipRequestApi = new MembershipRequestApi(self.environment.api)
      const results = yield* toGenerator(
        membershipRequestApi.searchOrganizationMembers(organizationId, query),
      )

      self.userStore.putUsers(results.users)

      return { ...results, users: self.userStore.putUsers(results.users) }
    }),
    searchGroupMembers: flow(function* (groupId: string, query: MembershipRequestQuery) {
      const membershipRequestApi = new MembershipRequestApi(self.environment.api)
      const results = yield* toGenerator(membershipRequestApi.searchGroupMembers(groupId, query))
      return { ...results, users: self.userStore.putUsers(results.users) }
    }),
  }))
  .views((self) => ({
    membershipRequestsByEntityId: (entityId) => {
      return Array.from(self.membershipRequests.values()).filter(
        (membershipRequest) =>
          membershipRequest &&
          (membershipRequest.groupId === entityId || membershipRequest.organizationId === entityId),
      )
    },
  }))

export type MembershipRequestStore = Instance<typeof MembershipRequestStoreModel>
export const withMembershipRequestStore = (self: IStateTreeNode) => ({
  views: {
    get membershipRequestStore(): MembershipRequestStore {
      return getParent<Instance<typeof RootStoreModel>>(self).membershipRequestStore
    },
  },
})
