import { clone, flow, Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { UserApi } from "../services/api/user-api"
import { withAssetStore } from "./asset-store"
import { withSessionStore } from "./session-store"
import { createField, createFieldModel, FormModel } from "../models/form"
import { AssetAddParams, AssetModel } from "../models/asset"
import { withGroupStore } from "../stores/group-store"
import { GroupModel, DiscoverabilityStatus } from "../models/group"
import { withUserStore } from "./user-store"
import { SyncStatus } from "../models/sync"
import { PitchApi } from "../services/api/pitch-api"

const ProfileFormModel = FormModel.props({
  firstName: createFieldModel({
    presence: { allowEmpty: false, message: "^First name cannot be blank" },
    length: { maximum: 256 },
  }),
  lastName: createFieldModel({
    presence: { allowEmpty: false, message: "^Last name cannot be blank" },
    length: { maximum: 256 },
  }),
  locationCity: createFieldModel({
    conditionalPresence: { other: "locationState", message: "^City cannot be blank" },
    length: { maximum: 128 },
  }),
  locationState: createFieldModel({
    conditionalPresence: { other: "locationCity", message: "^State cannot be blank" },
    length: { maximum: 64 },
  }),
  locationZip: createFieldModel({
    format: { pattern: /\d{5}(-\d{4})?/, message: "^Zip code is invalid" },
  }),
  bio: createFieldModel({
    length: {
      maximum: 1024,
    },
  }),
  website: createFieldModel({
    customUrl: { allowBlank: true },
    length: { maximum: 256 },
  }),
  avatarAssetId: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [
      self.firstName,
      self.lastName,
      self.locationCity,
      self.locationState,
      self.locationZip,
      self.bio,
      self.website,
      self.avatarAssetId,
    ]
  },
}))

const AccountFormModel = FormModel.props({
  phone: createFieldModel({
    format: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  }),
}).views((self) => ({
  get fields() {
    return [self.phone]
  },
}))

export const ProfileStoreModel = types
  .model("ProfileStore")
  .props({
    userProfileForm: types.maybe(ProfileFormModel),
    userProfileAvatarAsset: types.maybe(AssetModel),
    userAccountForm: types.maybe(AccountFormModel),
    profileGroups: types.map(types.array(types.safeReference(GroupModel))),
  })
  .extend(withEnvironment)
  .extend(withAssetStore)
  .extend(withSessionStore)
  .extend(withUserStore)
  .extend(withGroupStore)
  .actions((self) => ({
    removePrimaryProfilePitch: flow(function* (userId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      yield pitchApi.removePrimaryProfilePitch(userId)
      return true
    }),
    updateDiscoverability: flow(function* (userId: string, discoverability: DiscoverabilityStatus) {
      const userApi = new UserApi(self.environment.api)
      yield userApi.updateDiscoverability(userId, discoverability)
      return true
    }),
  }))
  .actions((self) => ({
    setProfilePhoto: function (params: AssetAddParams) {
      // for upload tracking
      const asset = self.assetStore.createAsset(params)
      self.userProfileAvatarAsset = asset

      // for dirty tracking
      self.userProfileForm?.avatarAssetId.setValue(self.userProfileAvatarAsset.id)
    },
    populateProfileForm: (user) => {
      self.userProfileForm = ProfileFormModel.create({
        firstName: createField("firstName", user.firstName),
        lastName: createField("lastName", user.lastName),
        locationCity: createField("locationCity", user.locationCity),
        locationState: createField("locationState", user.locationState),
        locationZip: createField("locationZip", user.locationZip),
        bio: createField("bio", user.bio),
        website: createField("website", user.website),
        avatarAssetId: createField("avatarAssetId", user.avatarAssetId),
      })
      if (user.avatarAssetId) {
        self.userProfileAvatarAsset = clone(self.assetStore.toAsset(user.avatarAssetId))
      }
    },
    saveProfileForm: flow(function* () {
      if (
        self.userProfileAvatarAsset &&
        self.userProfileAvatarAsset.sync.status === SyncStatus.New
      ) {
        // first upload the asset
        yield self.assetStore.uploadMobileAsset(self.userProfileAvatarAsset)
      }

      const api = new UserApi(self.environment.api)
      const valuesToSave = {
        firstName: self.userProfileForm?.firstName.value,
        lastName: self.userProfileForm?.lastName.value,
        locationCity: self.userProfileForm?.locationCity.value,
        locationState: self.userProfileForm?.locationState.value,
        locationZip: self.userProfileForm?.locationZip.value,
        bio: self.userProfileForm?.bio.value,
        website: self.userProfileForm?.website.value,
        avatarAssetId: self.userProfileForm?.avatarAssetId.value,
      }
      yield api.updateCurrentUser(valuesToSave)
      if (self.sessionStore.currentUser) {
        self.sessionStore.setCurrentUser({ ...self.sessionStore.currentUser, ...valuesToSave })
      }
      self.userProfileForm?.reset()
    }),
    resetProfileForm() {
      self.userProfileForm = undefined
      self.userProfileAvatarAsset = undefined
    },
    populateAccountForm: (user) => {
      self.userAccountForm = AccountFormModel.create({
        phone: createField("phone", user.phone),
      })
    },
    saveAccountForm: flow(function* () {
      const api = new UserApi(self.environment.api)
      const valuesToSave = {
        phone: self.userAccountForm?.phone.value,
      }
      yield api.updateCurrentUser(valuesToSave)
      if (self.sessionStore.currentUser) {
        self.sessionStore.setCurrentUser({ ...self.sessionStore.currentUser, ...valuesToSave })
      }
      self.userAccountForm?.reset()
    }),
    deleteAccount: flow(function* () {
      const api = new UserApi(self.environment.api)
      yield api.deleteCurrentAccount()
    }),
  }))

export type ProfileStore = Instance<typeof ProfileStoreModel>
