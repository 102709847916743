import { flow, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { AssignmentUserApi } from "../services/api/assignment-user-api"
import {
  AssignmentUser,
  AssignmentUserModel,
  AssignmentUserStatus,
} from "../models/assignment-user"

export const AssignmentUserStoreModel = types
  .model("AssignmentUserStore")
  .props({
    assignmentUsers: types.map(AssignmentUserModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    putAssignmentUsers(assignmentUsers: AssignmentUser[]) {
      return assignmentUsers.map((assignmentUser) => self.assignmentUsers.put(assignmentUser))
    },
  }))
  .actions((self) => ({
    fetchAssignmentUsers: flow(function* (options: {
      assignmentUserStatus?: AssignmentUserStatus
      cursor?: Date
    }) {
      const api = new AssignmentUserApi(self.environment.api)
      const result = yield* toGenerator(api.getAssignmentUsers(options))
      return self.putAssignmentUsers(result.assignmentUsers)
    }),
    fetchAssignmentUser: flow(function* (assignmentUserId: string) {
      const api = new AssignmentUserApi(self.environment.api)
      const result = yield* toGenerator(api.getAssignmentUser(assignmentUserId))
      return self.assignmentUsers.put(result.assignmentUser)
    }),
    hideAssignmentUser: flow(function* (assignmentUserId: string) {
      const api = new AssignmentUserApi(self.environment.api)
      yield* toGenerator(api.hideAssignmentUser(assignmentUserId))
    }),
    unhideAssignmentUser: flow(function* (assignmentUserId: string) {
      const api = new AssignmentUserApi(self.environment.api)
      yield* toGenerator(api.unhideAssignmentUser(assignmentUserId))
    }),
    completeAssignmentUser: flow(function* (assignmentUserId: string) {
      const api = new AssignmentUserApi(self.environment.api)
      yield* toGenerator(api.completeAssignmentUser(assignmentUserId))
    }),
    uncompleteAssignmentUser: flow(function* (assignmentUserId: string) {
      const api = new AssignmentUserApi(self.environment.api)
      yield* toGenerator(api.uncompleteAssignmentUser(assignmentUserId))
    }),
  }))
