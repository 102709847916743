import {
  detach,
  flow,
  getParent,
  Instance,
  IStateTreeNode,
  toGenerator,
  types,
} from "mobx-state-tree"
import { withUserAffiliationStore } from "./user-affiliation-store"
import { withEnvironment } from "./extensions/with-environment"
import { PlaylistApi } from "../services/api/playlist-api"
import { PitchApi } from "../services/api/pitch-api"
import { withSessionStore } from "./session-store"
import { withTimelineStore } from "./timeline-store"
import { RootStoreModel } from "./root-store"
import { sortBy } from "lodash-es"
import { Pitch, PitchModel, PitchStatus, PitchActionType } from "../models/pitch"
import { Entity } from "../models/entity"
import {
  StudioPitchInfoModel,
  StudioPitchInfoStatus,
  StudioPitch,
  StudioPitchInfo,
} from "../models/studio-pitch-info"

export const PitchStoreModel = types
  .model("PitchStore")
  .props({
    pitches: types.map(PitchModel),
    studioPitchInfos: types.map(StudioPitchInfoModel),
    pendingPitchIds: types.array(types.string),
    bookmarkedPitches: types.array(types.safeReference(PitchModel)),
    userPitches: types.array(types.safeReference(PitchModel)),
    userProfilePitches: types.map(types.safeReference(PitchModel)),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .extend(withTimelineStore)
  .extend(withUserAffiliationStore)
  .actions((self) => ({
    putPitch(pitch: Pitch) {
      return self.pitches.put(pitch)
    },
    putPitches(pitches: Pitch[]) {
      return pitches.map((pitch) => self.pitches.put(pitch))
    },
    addPendingPitch: (pitchId: string) => {
      if (!self.pendingPitchIds.some((id) => id === pitchId)) {
        self.pendingPitchIds.unshift(pitchId)
      }
    },
    removePendingPitch: (pitchId: string) => {
      const pitchIndex = self.pendingPitchIds.findIndex((id) => id === pitchId)
      if (pitchIndex > -1) {
        self.pendingPitchIds.splice(pitchIndex, 1)
      }
    },
  }))
  .actions((self) => ({
    removePitch(pitchId: string) {
      self.studioPitchInfos.delete(pitchId)
      self.removePendingPitch(pitchId)
      const pitch = self.pitches.get(pitchId)
      if (pitch) {
        detach(pitch)
      }
    },
    createStudioPitchInfo(pitchId: string, spi: StudioPitchInfo) {
      if (self.studioPitchInfos.has(pitchId)) {
        throw new Error("Studio pitch info already exists")
      }
      self.studioPitchInfos.set(pitchId, spi)
    },
    putStudioPitches(studioPitches: StudioPitch[]) {
      for (const studioPitch of studioPitches) {
        // have to convert the timelineId to a safeReference after confirming that it exists
        const timeline = self.timelineStore.timelines.get((studioPitch as any).timelineId)
        self.studioPitchInfos.set(
          studioPitch.id,
          Object.assign(studioPitch, { timeline: timeline?.id }),
        )
      }
      return self.putPitches(studioPitches)
    },
    bookmarkLocalPitch: (pitch: Pitch) => {
      if (!self.bookmarkedPitches.some((p) => p?.id === pitch.id)) {
        self.bookmarkedPitches.unshift(pitch.id)
      }
    },
    removeBookmarkedLocalPitch: (pitch: Pitch) => {
      const pitchIndex = self.bookmarkedPitches.findIndex((p) => p?.id === pitch.id)
      if (pitchIndex > -1) {
        self.bookmarkedPitches.splice(pitchIndex, 1)
      }
    },
  }))
  .actions((self) => ({
    fetchPitch: flow(function* (pitchId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      const result = yield* toGenerator(
        pitchApi.getPitch({
          pitchId,
        }),
      )
      return self.putPitches([result.pitch])[0]
    }),
    fetchStudioPitch: flow(function* (pitchId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      const result = yield* toGenerator(
        pitchApi.getStudioPitch({
          pitchId,
        }),
      )
      return self.putStudioPitches([result.pitch])[0]
    }),
    deletePitch: flow(function* (pitchId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      yield pitchApi.deletePitch({
        pitchId,
      })

      self.removePitch(pitchId)
    }),
    fetchProfilePitch: flow(function* (userId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      const result = yield* toGenerator(pitchApi.getProfilePitch(userId))
      if (result.pitch) {
        const [pitchModel] = self.putPitches([result.pitch])
        self.userProfilePitches.set(userId, pitchModel)
        return pitchModel
      }
      return null
    }),
    fetchPublishedPitchCount: flow(function* (userId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      const result = yield* toGenerator(
        pitchApi.getPublishedPitchCount({
          userId,
        }),
      )
      return result.publishedPitchCount
    }),
    // saved pitches
    fetchAllBookmarkedPitches: flow(function* (entityIds?: string[]) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getAllBookmarkedPitches(entityIds))

      const pitches = self.putPitches(result.pitches.map((p) => p.pitch))
      self.bookmarkedPitches.replace(result.pitches.map((p) => p.pitch.id) as any)
      return pitches
    }),
    fetchAllUserPitches: flow(function* (entityIds?: string[]) {
      const pitchApi = new PitchApi(self.environment.api)
      const result = yield* toGenerator(pitchApi.getAllUserPitches(entityIds))
      const pitches = self.putStudioPitches(result.pitches)

      // The entityId filter cases all use react-query, but we still have a lot of other cases
      // that expect unfiltered user pitches. Don't let the entityId filter cases mess with that.
      if (entityIds === undefined) {
        self.userPitches.replace(
          sortBy(result.pitches, (p) => p.displayUtc)
            .reverse()
            .map((p) => p.id) as any,
        )
      }
      return pitches
    }),
    bookmarkPitches: flow(function* (pitches: Pitch[]) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.bookmarkPitches({
        pitchIds: pitches.map((p) => p.id),
      })

      for (const pitch of pitches) {
        self.bookmarkLocalPitch(pitch)
      }
    }),
    removeBookmarkedPitches: flow(function* (pitches: Pitch[]) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.removeBookmarkedPitches({
        pitchIds: pitches.map((p) => p.id),
      })

      for (const pitch of pitches) {
        self.removeBookmarkedLocalPitch(pitch)
      }
    }),
    setProfilePitch: flow(function* (pitchId: string) {
      if (!self.sessionStore.currentUser) {
        throw new Error("Current user is not user")
      }
      const pitchApi = new PitchApi(self.environment.api)
      yield pitchApi.setProfilePitch(self.sessionStore.currentUser.id, pitchId)
    }),
    addProfilePitch: flow(function* (pitchId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.addProfilePitch(pitchId)
    }),
    removeProfilePitch: flow(function* (pitchId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.removeProfilePitch(pitchId)
    }),
  }))
  .actions((self) => ({
    publishPitch: flow(function* (pitchId: string, entityId?: string) {
      const pitchApi = new PitchApi(self.environment.api)
      yield pitchApi.publishPitch(pitchId, entityId)
    }),
    setEntity: flow(function* (pitch: Pitch, entity: Entity) {
      const pitchApi = new PitchApi(self.environment.api)
      const originalEntity = pitch.entity
      pitch.entity = entity
      try {
        yield pitchApi.setEntity(pitch.id, entity.id)
      } catch (e) {
        pitch.entity = originalEntity
        throw e
      }
    }),
    archivePitch: flow(function* (pitchId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      const pitch = self.pitches.get(pitchId)
      if (pitch) {
        pitch.status = PitchStatus.Archived
      }

      try {
        yield pitchApi.archivePitch(pitchId)
      } catch (e) {
        if (pitch) {
          pitch.status = PitchStatus.Published
        }
        throw e
      }
    }),
    unarchivePitch: flow(function* (pitchId: string) {
      const pitchApi = new PitchApi(self.environment.api)
      const pitch = self.pitches.get(pitchId)
      if (pitch) {
        pitch.status = PitchStatus.Published
      }

      try {
        yield pitchApi.unarchivePitch(pitchId)
      } catch (e) {
        if (pitch) {
          pitch.status = PitchStatus.Archived
        }
        throw e
      }
    }),
    createAction: flow(function* (
      pitchId,
      data: {
        actionType: PitchActionType
        actionEntityId: string
        actionTargetId?: string
        scheduledUtc?: Date
        assignmentUserId?: string
      },
    ) {
      const api = new PitchApi(self.environment.api)
      yield api.createAction(pitchId, data)
      const spi = self.studioPitchInfos.get(pitchId)
      if (spi) {
        Object.assign(spi, data)
      }
    }),
    initiateAction: flow(function* (pitchId) {
      const api = new PitchApi(self.environment.api)
      yield api.initiateAction(pitchId)
      const pitch = self.pitches.get(pitchId)
      const spi = self.studioPitchInfos.get(pitchId)
      if (
        pitch?.status === PitchStatus.Draft &&
        spi &&
        spi.status !== StudioPitchInfoStatus.Ready
      ) {
        spi.deferring = true
      }
    }),
    cancelAction: flow(function* (pitchId) {
      const api = new PitchApi(self.environment.api)
      yield api.cancelAction(pitchId)
      const spi = self.studioPitchInfos.get(pitchId)
      if (spi) {
        spi.actionType = PitchActionType.Publish
        spi.actionEntityId = undefined
        spi.actionTargetId = undefined
      }
    }),
  }))
  .views((self) => ({
    isBookmarked(pitchId: string) {
      return self.bookmarkedPitches.some((p) => p?.id === pitchId)
    },
    get playableUserPitches() {
      return self.userPitches.filter((p) => p?.thumbnailAssetId)
    },
    get userDrafts() {
      return self.userPitches.filter((p) => p?.status === PitchStatus.Draft)
    },
    get userPublishedPitches() {
      return self.userPitches.filter((p) => p?.status === PitchStatus.Published)
    },
    getUninitiatedPitches(actionType: PitchActionType, actionTargetId?: string): Pitch[] {
      return self.userPitches
        .filter((p) => {
          const spi = p?.id ? self.studioPitchInfos.get(p.id) : undefined
          return (
            spi &&
            !spi.deferring &&
            p?.status === PitchStatus.Draft &&
            spi.actionType === actionType &&
            spi.actionTargetId === actionTargetId
          )
        })
        .filter((p): p is Pitch => Boolean(p))
    },
    getInitiatedPitches(actionType: PitchActionType, actionTargetId?: string): Pitch[] {
      return self.userPitches
        .filter((p) => {
          const spi = p?.id ? self.studioPitchInfos.get(p.id) : undefined
          return (
            spi &&
            spi?.deferring &&
            spi.actionType === actionType &&
            spi.actionTargetId === actionTargetId
          )
        })
        .filter((p): p is Pitch => Boolean(p))
    },
  }))

export type PitchStore = Instance<typeof PitchStoreModel>
export const withPitchStore = (self: IStateTreeNode) => ({
  views: {
    get pitchStore(): PitchStore {
      return getParent<Instance<typeof RootStoreModel>>(self).pitchStore
    },
  },
})
